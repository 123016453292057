
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButton,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonList,
  IonSelectOption,

  onIonViewDidEnter,
 } from '@ionic/vue';

import { chevronBack, logOutOutline, checkmark, home } from 'ionicons/icons';

import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { Wedding, asDateObject, asServerString, ApiError, isApiError, getErrorMessage } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import MSelect from '@/components/MSelect.vue';
import MDatetime from '@/components/MDatetime.vue';
import MToggle from '@/components/MToggle.vue';
import { useStore } from '@/store';
import getDateLocale from '@/lib/locale-date-format';
import { getEnum, ServerEnum } from '@/lib/server-enums';
import * as yup from 'yup';
import t from '@/translation';
import { showModal } from '@/lib/modal';
import { useForm } from 'vee-validate';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButton,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonList,
    //IonItem,
    //IonLabel,
    //IonDatetime,
    IonSelectOption,


    MInput,
    MDatetime,
    MSelect,
    MToggle,
  },
  setup(){

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    

    const mealTypes = ref([] as ServerEnum[]);
    getEnum("meal_types").then((res)=>{
      mealTypes.value = res;
    });

    const weddingSchema = yup.object({
      /* eslint-disable @typescript-eslint/camelcase */

      bride_firstname: yup.string().min(2).required().label(t("Partner One First Name")),
      //bride_lastname: yup.string().min(2).required().label(t("Partner One Last Name")),
      bride_lastname: yup.string().label(t("Partner One Last Name")),
      groom_firstname: yup.string().min(2).required().label(t("Partner Two First Name")),
      //groom_lastname: yup.string().min(2).required().label(t("Partner Two Last Name")),
      groom_lastname: yup.string().label(t("Partner Two Last Name")),
      wedding_day: yup.string().required().label(t("Wedding Day")),
      wedding_time: yup.string().required().label(t("Wedding Time")),
      wedding_location: yup.string().default("").label(t("Wedding Location")),
      type: yup.number().min(0).max(1).required().label(t("Wedding Type")),
      budget: yup.number().required().min(0).label(t("Budget")),
      notification:yup.boolean().default(false).label(t("Notification")),

      /* eslint-enable @typescript-eslint/camelcase */
    });

    // Create a form context with the validation schema
    const { values, validate } = useForm({
      validationSchema: weddingSchema,
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        const weddingTimeObj = new Date(values.wedding_time!);
        const weddingDayObj = new Date(values.wedding_day!);

        const weddingDateObj = new Date(
          weddingDayObj.getFullYear(),
          weddingDayObj.getMonth(),
          weddingDayObj.getDate(),
          weddingTimeObj.getHours(),
          weddingTimeObj.getMinutes(),
        );

        const weddingDate = asServerString(weddingDateObj);
        console.log(weddingDate);

        const weddingParams: Wedding = {
      /* eslint-disable @typescript-eslint/camelcase */
          bride_firstname: values.bride_firstname!,
          bride_lastname: values.bride_lastname!,
          groom_firstname: values.groom_firstname!,
          groom_lastname: values.groom_lastname!,
          date: weddingDate,
          wedding_location: values.wedding_location,
          type: values.type!,
          budget: values.budget!,
          notification: values.notification
      /* eslint-enable @typescript-eslint/camelcase */
        }

        const wedding: ApiError | Wedding = await store.dispatch('updateWedding', weddingParams);
        if (isApiError(wedding)){
            showModal(t("Error Updating Wedding Info"), getErrorMessage(wedding));
        } else {
            showModal(t("Success"), t("Wedding Details Updated!"));
            router.push("/tabs/home");
        }
      } 
    }

    onIonViewDidEnter(async () => {
      const userData = await store.dispatch("getUserData", {force:true});
      if ((! userData) || isApiError(userData)){
        await showModal(t("Error Fetching Info"), t("This application was unable to retreive your user information.  Please make sure you have internet access, and then login again."));
        router.push("/login");
      } else if (store.state.userData && store.state.userData.wedding){
        // load the wedding details into the form
        Object.assign(values, userData.wedding);
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.wedding_day = (asDateObject(userData.wedding!.date)).toISOString()
        console.log(values.wedding_day);
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.wedding_time = values.wedding_day;
      }
    });

    onIonViewDidEnter(()=>{
      if (store.state.userData && (! store.state.userData.wedding)){
        showModal(t("Wedding Details"), t("Please fill out all of the fields on this page.  If you are unsure about anything, just guess.  You can change any of the information later from the settings page."));
      }
    });

    return {
      chevronBack,
      logOutOutline,
      checkmark,
      getDateLocale,
      mealTypes,
      onSubmitForm,
      home,
      store,
      router,
      route,
    }
  }
});

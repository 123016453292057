import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_m_item = _resolveComponent("m-item")!

  return (_openBlock(), _createBlock(_component_m_item, {
    label: _ctx.label,
    "wrap-label": true,
    "error-message": _ctx.errorMessage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toggle, {
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue = $event)),
        slot: "end"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["label", "error-message"]))
}